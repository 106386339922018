import axios from 'axios'
import BaseModel from '@/models/BaseModel.js'

export default class PaymentHistory extends BaseModel {
  fields =  {
    'order_id': {
      type: 'hidden',
      rules: null
    },
    'payment_method': {
      type: 'enum',
      options:{
        'cash': 'Cash',
        'transfer': 'Transfer Bank'
      },
      label: 'Metode Bayar',
      rules: 'required'
    },
    'amount': {
      type: 'price',
      label: 'Jumlah',
      rules: 'required'
    },
    'payment_date': {
      type: 'date',
      label: 'Tanggal',
      rules: 'required'
    },
    'attachment': {
      type: 'attachment',
      label: 'Lampiran, maks: 2MB',
      rules: null
    },
    'note': {
      type: 'textarea',
      label: 'Catatan',
      rules: null
    }
  }
  endpoint = process.env.VUE_APP_API_URL + 'payment-histories'
}