// import axios from 'axios'
import BaseModel from '@/models/BaseModel.js'

export default class PackageCategory extends BaseModel {
  fields =  {
    'name': {
      type: 'text',
      label: 'Nama',
      rules: 'required'
    },
    // 'description': {
    //   type: 'textarea',
    //   rules: null
    // },
    'parent_id': {
        type: 'enum',
        rules: null,
        options: [],
        label: 'Parent'
    }
  }
  endpoint = process.env.VUE_APP_API_URL + 'package-categories'
}