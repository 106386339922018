<template>
    <div id="accordion-document">
      <div class="text-left">
        <h5 class="pb-2">Kelengkapan Dokumen</h5>
      </div>
  
      <div v-for="(data, index) in dataRequired" :key="data.id">
        <b-alert show :variant="data.document_value !== null ? 'success' : 'danger'" v-b-toggle:[data.id]
          :key="index" class="my-2 d-flex justify-content-between align-items-center file-list-alert">
          <span>{{ data.label }}</span>
          <font-awesome-icon :icon="
            data.document_value !== null ? 'check-circle' : 'times-circle'
          "/>
        </b-alert>
        <div v-if="trigger"></div>
        <b-collapse :id="data.id" accordion="document" class="file-list-collapse">
          <!-- <div v-if="!document[index]" class="document-hidden"></div> -->
          <div class="image-send border rounded p-3 position-relative main-document" v-bind:class="{ 'has-additional':  data.additionals.length > 0 }" style="height: auto">
            <div class="image mb-3" v-if="data.document_value !== null || (document[index] && document[index] !== null && document[index] !== '')">
              <img @click="showPhoto" style="object-fit: contain; max-width: 250px;" :id="index + '-img'" :src="data.document_value !== null ? data.document_value : document[index]" />
            </div>
            <div class="button" v-if="data.document_value === null">
              <b-button class="w-100" v-if="document[index] == false" style="" @click.prevent="getFile(index)"
                variant="primary">
                Pilih Dokumen</b-button>
              <template v-else>
                <b-button @click.prevent="getFile(index)" style="color: white" class="w-100 btn-warning-2"
                  variant="warning">Pilih Ulang
                </b-button>
              </template>
              <p class="mt-2">Ektensi: {{ data.document_type }}. Ukuran maks: {{ parseInt(data.max_filesize / 1000) }}Mb</p>
            </div>

            
            <div v-for="additional in data.additionals" :key="additional.id" >
              <form-group-input v-if="data.document_value === null" :placeholder="additional.label" width-background :type="additional.type === 'string' ? 'text' : additional.type" :label="additional.label" :need-label="true" v-model="documentAdditional[additional.id]" :ref="'additional_' + additional.id"/>
              <span v-if="data.document_value !== null">{{additional.label}}</span>
              <div v-if="data.document_value !== null" class="custom-input mb-3"> {{ additional.type === 'date' ? $moment(additional.additional_value).format('DD-MM-YYYY') : additional.additional_value }} </div>
            </div>

            <b-button v-if="data.document_value === null && (document[index] && document[index] !== null && document[index] !== '')" @click.prevent="uploadFile(index)"
                style="color: white" class="w-100 mt-2 d-flex justify-content-center align-items-center"
                variant="primary">
                <b-spinner v-if="isLoading" small class="mr-2" type="grow" label="Spinning"></b-spinner>
                Upload File Terpilih
            </b-button>
            <b-button class="w-100" v-if="data.document_value !== null" variant="danger" @click.prevent="deleteDocument(data.document_id)">Hapus Dokumen</b-button>
            
          </div>
        
          <input @change="setDocument($event, index)" type="file" :ref="'file_' + index"
            v-show="false" />
        </b-collapse>
      </div>
  
      <photo ref="showDocument" :img="currentPhoto"/>
    </div>
  </template>
  
  <script>
  import Order from "@/models/Order";
  import FormGroupInput from "@/components/FormGroupInput.vue";
  import { getBase64FromUrl } from '@/_helpers'
  import Photo from "@/components/Photo.vue"
  
  const orderModel = new Order();
  
  export default {
    components: {
        FormGroupInput, Photo
    },
    data: () => ({
      trigger: true,
      document: [],
      documentAdditional: [],
      dataPasspor: [],
      urlAPI: process.env.VUE_APP_API_URL,
      dataRequired: [],
      isLoading: false,
      currentPhoto: ''
    }),
    methods: {
      showPhoto: function (e) {
        this.currentPhoto = e.srcElement.src
        this.$refs.showDocument.show()
      },
      uploadFile: async function (index) {
        const formData = new FormData();

        if (this.dataRequired[index].additionals.length > 0) {
            let hasError = false
            this.dataRequired[index].additionals.forEach(additional => {
                if (this.documentAdditional[additional.id] === '' || this.documentAdditional[additional.id] === null) {
                    this.$store.dispatch('notification/error', 'Harap lengkapi ' + additional.label + '.');
                    hasError = true
                }
            });
            if (hasError) {
                return false;
            }
        }
  
        this.isLoading = true
  
        formData.append("order_id", this.$route.params.id);
        formData.append("pilgrim_document_type_id", this.dataRequired[index].id);
        formData.append("document_file", this.document[index]);
        this.dataRequired[index].additionals.forEach((additional, index) => {
            formData.append('additionals[' + index + '][type_id]', additional.id)
            formData.append('additionals[' + index + '][value]', this.documentAdditional[additional.id])
        });
  
        // if (this.dataRequired[index].name == "passpor") {
        //   if (typeof this.document[index] == 'string') {
        //     const blobFile = await getBase64FromUrl(this.document[index])
        //   }
  
        //   formData.append('additionals[0][type_id]', this.dataRequired[index].additionals[0].id)
        //   formData.append('additionals[0][value]', this.dataPasspor[0])
        //   formData.append('additionals[1][type_id]', this.dataRequired[index].additionals[1].id)
        //   formData.append('additionals[1][value]', this.dataPasspor[1])
        // }
        orderModel.uploadPilgrimDocument(formData).then(resp => {
          this.getPilgrimDoc()
          // this.document[index] = resp.document_value_url
          this.trigger = false
          this.isLoading = false
          this.$store.dispatch('notification/info', 'Dokumen berhasil di kirim.')
        }).catch(err => {
          this.isLoading = false
          this.$store.dispatch('notification/error', err)
        })
      },
      getFile: function (index) {
        if (this.isLoading) return
        this.$refs["file_" + index][0].click();
      },
      setDocument: function (e, idx) {
        if (e.target.files.length) {
            const render = new FileReader();
            const validExd = this.dataRequired[idx].document_type.split(',')
    
            if (!validExd.includes(e.target.files[0].type.split('/')[1])) {
                this.$store.dispatch('notification/error', 'Ekstensi file tidak diizinkan')
                // alert('extensition file tidak diizinkan')
                return
            }

            if (e.target.files[0].size > (this.dataRequired[idx].max_filesize * 1000)) {
                this.$store.dispatch('notification/error', 'Maksimal ukuran file: ' + parseInt(this.dataRequired[idx].max_filesize / 1000) + 'mb')
                return
            }
    
            this.document[idx] = e.target.files[0];
    
            this.trigger = false;
            render.onload = function () {
              const output = document.getElementById(idx + "-img");
              output.src = render.result;
            };
            setTimeout(() => {
              render.readAsDataURL(this.document[idx]);
              this.trigger = true;
            }, 500);
        } else {
            this.trigger = false;
            this.document[idx] = false
            const output = document.getElementById(idx + "-img");
            output.src = null;
        }
      },
      getPilgrimDoc: function () {
        this.document = []
        this.documentAdditional = []
        orderModel.listPilgrimDocument(this.$route.params.id).then(resp => {
            this.dataRequired = resp
            this.dataRequired.forEach(data => {
                this.document.push(data.document_value !== null ? data.document_value : false)
                data.additionals.forEach(additional => {
                  this.documentAdditional[additional.id] = additional.additional_value
                })
                if (data.document_value === null) {
                    this.$nextTick(() => {
                    data.additionals.forEach(additional => {
                        this.$refs['additional_' + additional.id][0].setValue(additional.additional_value)
                    })
                    })
                }
            });
        }).catch(err => {
          this.$store.dispatch('notification/error', err)
        })
      },
      deleteDocument: function (id) {
        this.$swal.fire({
          title: 'Yakin Ingin Menghapus Data Ini?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak',
        }).then(result => {
            if (result.value) {
            this.isLoading = true
              orderModel.deletePilgrimDocument(id).then(resp => {
                this.getPilgrimDoc()
                this.isLoading = false
                this.$store.dispatch('notification/info', 'Dokumen berhasil di hapus.')
              }).catch(err => {
                this.isLoading = false
                this.$store.dispatch('notification/error', err)
              })
            }
        })
      }
    },
    created: function () {
      this.getPilgrimDoc();
    },
    mounted: function () {
      this.trigger = false
    },
  };
  </script>