import axios from 'axios'
import BaseModel from '@/models/BaseModel.js'

export default class Order extends BaseModel {
  fields =  {
  }
  endpoint = process.env.VUE_APP_API_URL + 'orders'

  create (value) {
    return new Promise((resolve, reject) => {
      axios.post(this.endpoint, value).then(response => {
        resolve(response.data.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  uploadPilgrimDocument(formData) {
    return new Promise((resolve, reject) => {
      axios.post(this.endpoint + '/pilgrim-documents', formData)
        .then(resp => {
          resolve(resp.data)
        }).catch(e => {
          reject(e)
        })
    }) 
  }

  listPilgrimDocument(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(this.endpoint + '/pilgrim-documents?order_id=' + id).then((resp) => {
          resolve(resp.data)
        }).catch((err) => {
          reject(err)
        })
    })
  }

  deletePilgrimDocument(id) {
    return new Promise((resolve, reject) => {
      axios.delete(this.endpoint + '/pilgrim-documents/' + id)
        .then(resp => {
          resolve(resp.data)
        }).catch(e => {
          reject(e)
        })
    }) 
  }

}