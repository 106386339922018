<template>
  <b-card class="mb-4" header-tag="header">
    <template #header>
      <b-row>
        <b-col cols="10">
          <h5 class="mb-0">{{$route.name}}</h5>
        </b-col>
        <b-col cols="2" class="text-right">
          <!-- <b-button-group class="add-button-group">
            <b-btn variant="primary" href="#/orders/create">Tambah Data</b-btn>
          </b-button-group> -->
        </b-col>
      </b-row>
    </template>
    <b-row class="mb-3" style="margin-top: -20px;">
      <b-col cols="12">
        <b-form @submit.prevent="doFilter">
          <h6 class="mb-1" style="font-size: 1rem;">Filter Data</h6>
          <b-row class="filter-row">
            <b-col cols="2">
              <form-group-input ref="filter-q" type="text" placeholder="" :need-label="true" label="customer" v-model="filter.q" id="filter-q" />
            </b-col>
            <b-col cols="2">
              <form-group-input ref="filter-package_name" type="text" placeholder="" :need-label="true" label="produk" v-model="filter.package_name" id="filter-package_name" />
            </b-col>
            
            <b-col cols="2">
              <form-group-input ref="filter-notification_status" :options="[{
                value: '',
                text: 'Semua'
              }, {
                value: 'pending',
                text: 'Pending'
              }, {
                value: 'sending',
                text: 'Sending'
              }, {
                value: 'succeed',
                text: 'Succeed'
              }, {
                value: 'failed',
                text: 'Failed'
              }]" type="enum" placeholder="" :need-label="true" label="status" v-model="filter.notification_status" id="filter-notification_status" />
            </b-col>
            <b-col cols="3">
              <form-group-input ref="filter-date_period" type="daterange"  placeholder="" :need-label="true" label="periode" v-model="filter.date_period" id="filter-date_period" />
            </b-col>
            <b-col cols="3">
              <label class="form-label">&nbsp;</label><br>
              <b-btn type="submit" variant="primary">Cari</b-btn>
              <b-btn type="button" @click.prevent="clearForm" variant="secondary" class="ml-3">Clear</b-btn>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
    <paginated-table ref="dataTable" :can-delete="true" :can-edit="false" :need-select="true" :data-url="apiUrl.endpoint" :data-columns="columns" :dataParams="dataParams" :defaultDataParams="this.filter">
      <template slot="content_created_at" slot-scope="defaultSlotScope">
        {{defaultSlotScope.colData.created_at !== null ? $moment(defaultSlotScope.colData.created_at).format('DD-MM-YYYY') : ''}}
      </template>
      <template slot="content_customer_name" slot-scope="defaultSlotScope">
        {{defaultSlotScope.colData.customer !== null ? defaultSlotScope.colData.customer.name : ''}} <a href="#" style="float: right; padding: 0px 5px;" @click.prevent="openGallery(defaultSlotScope.colData.payment_proof_url)"><font-awesome-icon icon="search-plus"/></a>
      </template>
      <template slot="content_customer_phone_number" slot-scope="defaultSlotScope">
        {{defaultSlotScope.colData.customer !== null ? defaultSlotScope.colData.customer.phone_number.replace('+62', '0') : ''}}
      </template>
      <template slot="content_cs_name" slot-scope="defaultSlotScope">
        {{defaultSlotScope.colData.agent !== null ? defaultSlotScope.colData.agent.name : ''}}
      </template>
      <template slot="content_notification_status" slot-scope="defaultSlotScope">
        <p>
          <b-badge variant="secondary" v-if="defaultSlotScope.colData.notification_status === 'pending'">Pending</b-badge>
          <b-badge variant="secondary" v-if="defaultSlotScope.colData.notification_status === 'sending'">Sending</b-badge>
          <b-badge variant="success" v-else-if="defaultSlotScope.colData.notification_status === 'succeed'">Sukses</b-badge>
          <b-badge variant="danger" v-else-if="defaultSlotScope.colData.notification_status === 'failed'">Gagal</b-badge>
        </p>
      </template>
      <template slot="content_produk" slot-scope="defaultSlotScope">
        <span v-html="implodeProduct(defaultSlotScope.colData.details)"></span>
      </template>
      <!-- <template slot="content_action" slot-scope="defaultSlotScope">
        <b-button variant="primary" size="sm" :href="'/#/orders/' + defaultSlotScope.colData.id" class="mb-0 px-2 text-center detail mr-1">
          &nbsp;<font-awesome-icon icon="info"/>&nbsp;
        </b-button>
        <b-button variant="danger" size="sm" type="button" class="mb-0 text-center delete ml-1">
          <font-awesome-icon icon="trash"/>
        </b-button>
      </template> -->
    </paginated-table>
    <div>
      <b-btn type="button" variant="success" class="float-right" @click.prevent="doExport"><font-awesome-icon icon="file-excel"/> Export Ke Excel</b-btn>
    </div>
    <LightBox ref="lightbox" :show-light-box="false" :showThumbs="false" :showFooterCount="false" :show-caption="false" :media="[{
      thumb: modalImage,
      src: modalImage
    }]" />
  </b-card>
</template>

<script>
// import { objectToOptions, getYears, formatModelValue } from '@/_helpers'
import paginatedTable from '@/components/PaginatedTable.vue'
import formGroupInput from '@/components/FormGroupInput.vue'
import Order from '@/models/Order.js'
import LightBox from 'vue-image-lightbox'

const orderModel = new Order()

export default {
  components: {
    formGroupInput,
    paginatedTable,
    LightBox
  },
  data () {
    return {
      isLoading: false,
      apiUrl: {
        endpoint: orderModel.getEndpoint()
      },
      columns: [
        // {
        //   fixed: 'left',
        //   prop: 'q',
        //   label: 'No. Order',
        //   sortable: true,
        //   resizable: true,
        //   minWidth: 76
        // },
        {
          prop: 'created_at',
          label: 'Tanggal',
          align: 'center',
          sortable: true,
          minWidth: 45
        },
        {
          prop: 'customer_name',
          label: 'Nama Customer',
          align: 'left',
          sortable: false,
          minWidth: 100
        },
        {
          prop: 'customer_phone_number',
          label: 'Hp Customer',
          align: 'left',
          sortable: false,
          minWidth: 100
        },
        {
          prop: 'cs_name',
          label: 'CS',
          align: 'left',
          sortable: false,
          minWidth: 60
        },
        {
          prop: 'produk',
          label: 'Produk',
          align: 'left',
          sortable: false,
          minWidth: 76
        },
        {
          prop: 'notification_status',
          label: 'Notif Status',
          align: 'center',
          sortable: true,
          minWidth: 50
        },
        // {
        //   prop: 'action',
        //   label: '',
        //   align: 'center',
        //   sortable: false,
        //   minWidth: 50
        // },
      ],
      filter: {
        q: typeof this.$route.query.q !== 'undefined' ? this.$route.query.q : null,
        package_name: typeof this.$route.query.package_name !== 'undefined' ? this.$route.query.package_name : null,
        notification_status: typeof this.$route.query.notification_status !== 'undefined' ? this.$route.query.notification_status : null,
        date_period: typeof this.$route.query.date_period !== 'undefined' ? this.$route.query.date_period : null
      },
      dataParams: ['q', 'package_name', 'notification_status', 'date_period'],
      modalImage: null
    }
  },
  beforeMount () {
    this.$router.push({query: {...this.$route.query}}).catch(err => {})
  },
  mounted () {
  },
  methods: {
    openGallery(url) {
      this.modalImage = url
      this.$refs.lightbox.showImage([0])
    },
    implodeProduct (details) {
      let result = []
      details.forEach(detail => {
        result.push('<a target="_blank" href="' + detail.package.url_download + '">' + detail.package.name + '</a>')
      });
      return result.join(', ')
    },
    clearForm () {
      this.$nextTick(() => {
        this.filter = {
          q: null,
          package_name: null,
          notification_status: null,
          date_period: null,
        }
        setTimeout(() => {
          this.doFilter()
        }, 100)
      })
    },
    doFilter () {
      this.$refs.dataTable.doFilter(this.filter)
    },
    doExport () {
      let params = []
      Object.keys(this.filter).forEach((key) => {
        if (this.filter[key] !== null && this.filter[key] !== '') {
          params.push(key + '=' + this.filter[key])
        }
      })
      this.$http.get(orderModel.getEndpoint() + '?export_csv=1&' + params.join('&')).then(response => {
        console.log(response.data)
        const url = window.URL.createObjectURL(new Blob([response.data])) 
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', "Data_Order.csv")
        document.body.appendChild(link)
        link.click()
        link.remove()
      }).catch(error => {
        console.log(error)
        alert('Gagal export, silahkan coba lagi')
      })
    }
  }
}
</script>