<template>
  <b-card header-tag="header" body-bg-variant="light">
      <template #header>
          <h5 class="mb-0">{{$route.name}}</h5>
      </template>
      <ValidationObserver ref="form">
        <form @submit.prevent="onSubmit">
            <template v-for="(m, index) in model">
              <form-group-input :key="index" :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="getOptions(index)" :need-label="true" v-model="m.value" rows="7" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
            </template>
            <p>
                <ul>
                  <li v-html="'$customername : Variable nama customer'"></li>
                  <li v-html="'$orderno : Variable nama customer'"></li>
                  <li v-html="'$details : Link link asset yang dibeli'"></li>
                </ul>
            </p>
            <div>
              <b-button type="submit" variant="primary" class="mt-3"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> Simpan</b-button>
            </div>
          </form>
      </ValidationObserver>
    </b-card>
</template>

<script>
import { saveProcess, setModelValue, scrollToError, objectToOptions } from '@/_helpers'
import formGroupInput from '@/components/FormGroupInput.vue'
import WaMessageConfig from '@/models/WaMessageConfig.js'

const waMessageConfigModel = new WaMessageConfig()

export default {
components: {
  formGroupInput
},
watch: {
  '$route.params.id': function (val) {
    if (this.mode === 'update') {
      this.getDetail()
    }
  }
},
computed: {
  mode: function () {
    return 'update'
  }
},
data () {
  return {
    isLoading: false,
    modelId: null,
    model: waMessageConfigModel.init()
  }
},
mounted () {
  if (this.mode === 'update') {
    this.getDetail()
  }
},
methods: {
  getOptions (key) {
    return typeof this.model[key].options !== 'undefined' ? objectToOptions(this.model[key].options) : null
  },
  getDetail () {
    waMessageConfigModel.list({
      'type': 'general'
    }).then(resp => {
      if (resp.data.length > 0) {
        this.modelId = resp.data[0].id
        resp = resp.data[0]
        resp.template_content.replace(/\n/g,' ')
        console.log(resp.template_content)
        setModelValue(this.model, resp)
        Object.keys(this.model).forEach(key => {
          this.$refs[key][0].setValue(this.model[key].value)
        })
    }
    }).catch(error => {
      if (process.env.NODE_ENV !== 'production') {
        console.error(error)
      }
    })
  },
  resetForm () {
    this.$nextTick(() => {
      // clearModelValue(this.model)
      Object.keys(this.model).forEach(key => {
        this.$refs[key][0].setValue(null)
      })
      this.$refs.form.reset()
    })
  },
  onSubmit () {
    this.$refs.form.validate().then(success => {
      if (!success || this.isLoading) {
        scrollToError(this.$refs.form)
        return false
      }
      saveProcess(this.model, this.mode === 'update').then(model => {
        this.isLoading = true
        if (this.mode === 'update') {
          waMessageConfigModel.update(this.modelId, model).then(() => {
            this.isLoading = false
            this.$store.dispatch('notification/success', 'Data berhasil diubah.')
            // this.$router.push('/package-categories/' + this.$route.params.id)
          }).catch(error => {
            this.isLoading = false
            this.$store.dispatch('notification/error', error)
          })
        } else {
          waMessageConfigModel.create(model).then(resp => {
            this.isLoading = false
            // this.resetForm()
            this.$store.dispatch('notification/success', 'Data kategori berhasil disimpan.')
            this.$router.push('/package-categories/' + resp.id)
          }).catch(error => {
            this.isLoading = false
            this.$store.dispatch('notification/error', error)
          })
        }
      }).catch(() => {})
    })
  }
}
}
</script>