<template>
  <div>
    <b-row class="mb-1">
      <b-col cols="9">
        <h5 class="mb-0">Data Pembayaran</h5>
      </b-col>
      <b-col cols="3" class="text-right">
        <b-button-group class="add-button-group">
          <b-btn variant="primary" @click.prevent="showModal()">Tambah Pembayaran</b-btn>
        </b-button-group>
      </b-col>
    </b-row>
    <paginated-table ref="dataTable" :delete-reason="false" :can-delete="false" :can-edit="false" :data-url="apiUrl.paymentHistory" :data-columns="columns" :dataParams="dataParams" :defaultDataParams="this.filter" :no-push-param="true">
      <template slot="content_payment_method" slot-scope="defaultSlotScope">
        {{ defaultSlotScope.colData.payment_method === 'cash' ? 'Cash' : 'Transfer Bank' }}
      </template>
      <template slot="content_attachment" slot-scope="defaultSlotScope">
        <b-button v-if="defaultSlotScope.colData.attachment !== null" variant="primary" size="sm" :href="defaultSlotScope.colData.attachment" target="_blank" class="mb-0 text-center edit">
          Unduh
        </b-button>
        <p v-else>-</p>
      </template>
      <template slot="actionButtons" slot-scope="defaultSlotScope">
        <b-row class="justify-content-center table-actions">
          <b-button variant="danger" size="sm" type="button" v-on:click.stop.prevent="doDeleteData(defaultSlotScope.colData.id)" class="mb-0 text-center delete ml-1">
            <font-awesome-icon icon="trash"/>
          </b-button>
        </b-row>
      </template>
    </paginated-table>

    <!-- Butuh update Pembayaran -->
    <b-modal
      id="modal-1"
      ref="modal-1"
      :title="additionalModels.mode === 'create' ? 'Tambah Data Pembayaran' : 'Edit Data Pembayaran'"
      :okTitle="isLoading ? 'Menyimpan...' : 'Simpan'"
      cancelTitle="Batal"
      @shown="modalShown"
      @ok="handleOk"
    >
    <ValidationObserver ref="form">
      <form @submit.prevent="handleSubmit">
        <template v-for="(m, index) in model">
          <form-group-input :key="index" :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
        </template>
      </form>
    </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import { formatModelValue, objectToOptions, scrollToError, serializeModel, setModelValue } from '@/_helpers'
import formGroupInput from '@/components/FormGroupInput.vue'
import paginatedTable from '@/components/PaginatedTable.vue'
import PaymentHistory from '@/models/PaymentHistory.js'

const paymentHistoryModel = new PaymentHistory()

export default {
  components: {
    formGroupInput,
    paginatedTable
  },
  props: {
    orderId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      apiUrl: {
        paymentHistory: paymentHistoryModel.getEndpoint()
      },
      model: paymentHistoryModel.init(),
      additionalModels: {
        mode: 'create',
        paymentHistory: null
      },
      columns: [
        {
          prop: 'payment_date',
          label: 'Tanggal',
          align: 'center',
          sortable: true,
          resizable: false,
          minWidth: 30
        },
        {
          prop: 'amount',
          label: 'Total',
          needCurrency: true,
          align: 'right',
          sortable: true,
          resizable: false,
          minWidth: 40
        },
        {
          prop: 'payment_method',
          label: 'Metode Bayar',
          align: 'center',
          sortable: true,
          resizable: false,
          minWidth: 40
        },
        {
          prop: 'attachment',
          label: 'Lampiran',
          align: 'center',
          resizable: false,
          sortable: false,
          minWidth: 30
        },
        {
          prop: 'note',
          label: 'Catatan',
          sortable: false,
          resizable: true,
          minWidth: 70
        }
      ],
      filter: {
        order_id: this.orderId
      },
      dataParams: ['order_id']
    }
  },
  beforeMount () {
    this.$router.push({query: {...this.$route.query}}).catch(err => {})
  },
  mounted () {
  },
  methods: {
    getOptions (key) {
      return typeof this.model[key].options !== 'undefined' ? objectToOptions(this.model[key].options) : null
    },
    formatCurrency (value) {
      return formatModelValue('price', value)
    },
    doFilter () {
      this.$refs.dataTable.doFilter(this.filter)
    },
    resetModal () {
      this.$nextTick(() => {
        // clearModelValue(this.model)
        Object.keys(this.model).forEach(key => {
          if (key !== 'order_id' && key !== 'step' && typeof this.$refs[key] !== 'undefined' && this.$refs[key].length) {
            this.$refs[key][0].setValue(null)
          }
        })
        if (typeof this.$refs.form !== 'undefined') {
          this.$refs.form.reset()
        }
      })
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    showModal () {
      this.$nextTick(() => {
        this.additionalModels.mode = 'create'
        this.additionalModels.id = null
        this.$refs['modal-1'].show()
        // this.$bvModal.show('modal-1')
      })
    },
    modalShown () {
      this.$nextTick(() => {
        this.resetModal()
        this.model.order_id.value = this.orderId
      })
    },
    doDeleteData (id) {
      paymentHistoryModel.delete(id).then(() => {
        this.isLoading = false
        this.$store.dispatch('notification/success', 'Data pembayaran berhasil dihapus.')
        this.doFilter()
        this.$emit('afterDelete', null)
      }).catch(error => {
        this.isLoading = false
        this.$store.dispatch('notification/error', error)
      })
    },
    handleSubmit() {
      this.$nextTick(() => {
        this.$refs.form.validate().then(success => {
          if (!success || this.isLoading) {
            scrollToError(this.$refs.form)
            return false
          }
          this.isLoading = true
          if (this.additionalModels.mode === 'update') {
            paymentHistoryModel.update(this.additionalModels.id, serializeModel(this.model)).then(resp => {
              this.isLoading = false
              this.$store.dispatch('notification/success', 'Data pembayaran berhasil diubah.')
              this.$nextTick(() => {
                this.$refs['modal-1'].hide()
                // this.$bvModal.hide('modal-1')
              })
              this.doFilter()
              this.$emit('afterSubmit', resp)
            }).catch(error => {
              this.isLoading = false
              this.$store.dispatch('notification/error', error)
            })
          } else {
            paymentHistoryModel.create(serializeModel(this.model)).then(resp => {
              this.isLoading = false
              this.$store.dispatch('notification/success', 'Data pembayaran berhasil disimpan.')
              this.$nextTick(() => {
                this.$refs['modal-1'].hide()
                // this.$bvModal.hide('modal-1')
              })
              this.doFilter()
              this.$emit('afterSubmit', resp)
            }).catch(error => {
              this.isLoading = false
              this.$store.dispatch('notification/error', error)
            })
          }
        })
      })
    },
    getFileNameFromUri (uri) {
      return uri.substring(uri.lastIndexOf('/')+1)
    }
  }
}
</script>