// import axios from 'axios'
import BaseModel from '@/models/BaseModel.js'

export default class Agent extends BaseModel {
  fields =  {
    'name': {
      type: 'text',
      label: 'Nama',
      rules: 'required'
    },
    'phone_number': {
        type: 'text',
        rules: 'required',
        label: 'No. HP'
    }
  }
  endpoint = process.env.VUE_APP_API_URL + 'agents'
}