<template>
  <div>
    <b-card header-tag="header">
      <template #header>
        <b-row>
          <b-col cols="10">
            <h5 class="mb-0">{{$route.name}}</h5>
          </b-col>
          <b-col cols="2">
            <div class="d-flex">
              <b-btn variant="secondary" :href="'#/package-categories/' + $route.params.id + '/edit'" class="ml-auto">Edit</b-btn>
            </div>
          </b-col>
        </b-row>
      </template>
      <b-row>
        <template v-for="(m, index) in model">
          <b-col :key="index" cols="4" v-if="(typeof m.disabled === 'undefined' || !m.disabled) && m.type !== 'hidden'">
            <detail-group-input :key="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :value="m.value" :id="index">
            </detail-group-input>
          </b-col>
        </template>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { setModelValue } from '@/_helpers'
import detailGroupInput from '@/components/DetailGroupInput.vue'
import PackageCategory from '@/models/PackageCategory.js'

const packageCategoryModel = new PackageCategory()

export default {
  components: {
    detailGroupInput
  },
  watch: {
    '$route.params.id': function (val) {
      if (val !== undefined) {
        this.getDetail()
      }
    }
  },
  data () {
    return {
      isLoading: false,
      model: packageCategoryModel.init()
    }
  },
  mounted () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      this.isLoading = true
      packageCategoryModel.find(this.$route.params.id).then(resp => {
        this.isLoading = false
        setModelValue(this.model, resp)
        this.model.parent_id.value = '-'
        if (resp.parent_id !== null) {
          this.getParentDetail(resp.parent_id)
        }
      }).catch(error => {
        if (process.env.NODE_ENV !== 'production') {
          console.error(error)
        }
        this.isLoading = false
      })
    }, 
    getParentDetail (id) {
      packageCategoryModel.find(id).then(resp => {
        this.model.parent_id.value = resp.name
      }).catch(error => {
        if (process.env.NODE_ENV !== 'production') {
          console.error(error)
        }
      })
    }
  }
}
</script>