// import axios from 'axios'
import BaseModel from '@/models/BaseModel.js'

export default class SuperAdmin extends BaseModel {
  fields =  {
    'name': {
      type: 'text',
      label: 'Nama',
      rules: 'required'
    },
    'email': {
        type: 'email',
        rules: 'required',
        label: 'Email'
    }
  }
  endpoint = process.env.VUE_APP_API_URL + 'super-admins'
}