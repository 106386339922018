// import axios from 'axios'
import BaseModel from '@/models/BaseModel.js'

export default class WaMessageConfig extends BaseModel {
  fields =  {
    'template_content': {
      type: 'textarea',
      label: 'Content',
      rules: 'required'
    }
  }
  endpoint = process.env.VUE_APP_API_URL + 'wa-message-configs'
}