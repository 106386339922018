<template>
    <div id="document_form_order">
      <div class="px-4 py-3">
        <b-table :fields="fields" :items="documentList" class="table-dokumen">
          <template #cell(Name)="row">
            <span>{{
              row.item.name
                .split("-")
                .map((word) => word[0].toUpperCase() + word.slice("1"))
                .join(" ")
            }}</span>
          </template>
          <template #cell(Status)="row">
            <span class="h3">
              <!-- <b-icon
                v-if="row.item.document_value"
                variant="success"
                icon="check-circle-fill"
              ></b-icon>
              <b-icon v-else variant="danger" icon="x-circle-fill"></b-icon> -->
            </span>
          </template>
  
          <template #cell(Detail)="row">
            <b-button variant="warning" @click="row.toggleDetails">
              <font-awesome-icon icon="info" />
            </b-button>
          </template>
  
          <template #row-details="row">
            <b-card class="p-4 detail-document">
              <div class="d-flex">
                <div>
                  <div class="img">
                    <img
                      :style="{
                        'object-fit': 'contain',
                        display: row.item.document_value ? 'block' : 'none',
                      }"
                      :id="row.index + '-img'"
                      :src="
                        row.item.document_value ? row.item.document_value : ''
                      "
                      alt=""
                    />
                    <template>
                      <span
                        v-if="!row.item.document_value"
                        class="h1 card-image-wrapper"
                      >
                        <font-awesome-icon icon="image" />
                      </span>
                      <span
                        @click="
                          () => {
                            documentChosee.id = row.item.id;
                            documentChosee.name = row.item.name
                              .split('-')
                              .map(
                                (word) => word[0].toUpperCase() + word.slice('1')
                              )
                              .join(' ');
                            $bvModal.show('modal-unggah-dokumen');
                          }
                        "
                        class="btn-upload-document"
                        >Upload Dokumen</span
                      >
                      <input
                        style="display: none"
                        @change="setImgPreview($event, row.index)"
                        type="file"
                        :ref="row.index + '-file'"
                        accept="image/*"
                      />
                    </template>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="ml-5 d-flex flex-column align-items-start">
                    <!-- <span class="p"
                      >Ektensi:
                      {{ row.item.document_type }}</span
                    >
                    <span class="p"
                      >Ukuran maks: {{ parseInt(row.item.max_filesize / 1000) }}Mb</span
                    > -->
                    <template v-if="row.item.name == 'passpor'">
                      <span class="p"
                        >Nomor Passpor:
                        {{ row.item.additionals[0].additional_value }}</span
                      >
                      <span class="p"
                        >Tanggal Expired:
                        {{ row.item.additionals[1].additional_value }}</span
                      >
                    </template>
                  </div>
                </div>
              </div>
            </b-card>
          </template>
        </b-table>
      </div>
  
      <!-- modal -->
      <b-modal id="modal-unggah-dokumen" centered :title="documentChosee.name">
        <div class="upload-img d-flex flex-column ">
          <div class="">
            <div
              class="img-space"
              @drop.prevent="doDrop"
              @dragleave="doDragLeave"
              @dragover="doDragOver"
            >
              <img style="display: none" id="img-preview" :src="imgDataUrl"  alt="" />
  
              <div id="show-before-preview">
                <span class="h5 card-image-wrapper">
                  <font-awesome-icon icon="image" />
                  Pilih Dokumen
                </span>
                <b-button @click="$refs['input-upload-file'].click()"
                  >Upload Dokumen</b-button
                >
              </div>
  
              <div id="show-over">
                <span
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-dropbox"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8.01 4.555 4.005 7.11 8.01 9.665 4.005 12.22 0 9.651l4.005-2.555L0 4.555 4.005 2 8.01 4.555Zm-4.026 8.487 4.006-2.555 4.005 2.555-4.005 2.555-4.006-2.555Zm4.026-3.39 4.005-2.556L8.01 4.555 11.995 2 16 4.555 11.995 7.11 16 9.665l-4.005 2.555L8.01 9.651Z"
                    />
                  </svg>
  
                  Drop Kesini
                </span>
              </div>
            </div>
          </div>
          <input
            style="margin: 15px 35px; display: none;"
            type="file"
            @change="setImgPreview"
            accept="image/*"
            id="input-upload-file"
            ref="input-upload-file"
          />
          <div v-if="documentChosee.name == 'Passpor'" class="mt-3">
            <FormGroupInput
              placeholder=""
              width-background
              type="number"
              v-model="passporPostDetail.number"
              label="Nomor Passpor"
              :need-label="true"
              rules="required"
            />
            <FormGroupInput
              placeholder=" "
              width-background
              type="date"
              v-model="passporPostDetail.expired"
              label="Tanggal Expired"
              :need-label="true"
              rules="required"
            />
          </div>
          <div class="action">
            <b-button
              @click="$bvModal.hide('modal-unggah-dokumen')"
              variant="secondary"
              >Batal</b-button
            >
            <b-button @click="uploadPilgrimDocument" variant="primary">
              <font-awesome-icon
                icon="spinner"
                spin
                v-if="isLoading"
              ></font-awesome-icon>
              Unggah
            </b-button>
          </div>
        </div>
      </b-modal>
    </div>
  </template>
  
  <script>
  import Order from "@/models/Order.js"
  import FormGroupInput from "@/components/FormGroupInput"
  
  const orderModel = new Order();
  
  export default {
    components: {
      orderModel,
      FormGroupInput
    },
    data: () => ({
      show: true,
      fields: ["Name", "Status", "Detail"],
      documentList: [],
      document: [],
      documentPost: null,
      documentChosee: { id: null, name: null },
      passporPostDetail: { expired: null, number: null },
      isLoading: false,
      params: {
        URL: "",
      },
      headers: {},
      detail: {
        hint: "Klik Atau Seret File Disini",
        loading: "Mohon Tunggu Sebentar",
        noSupported:
          "Browser Tidak Support, Mohon Gunakan Browser Lain",
        success: "Gambar Berhasil Di Upload",
        fail: "Gagal Mengupload",
        preview: "Preview",
        btn: {
          off: "Batal",
          close: "Keluar",
          back: "Kembali",
          save: "Unggah",
        },
        error: {
          onlyImg: "Mohon Masukkan Gambar",
          outOfSize: "Gambar melebihi batas ukuran",
          lowestPx: "Ukuran gambar terlalu kecil",
        },
      },
      imgDataUrl:{}
    }),
    watch: {
      documentChosee: {
        handler: function(value) {
          console.log(value);
        },
        deep: true,
      },
    },
    methods: {
      uploadPilgrimDocument: function() {
        this.isLoading = true;
  
        const formData = new FormData();
        formData.append("order_id", this.$route.params.id);
        formData.append("pilgrim_document_type_id", this.documentChosee.id);
        formData.append("document_file", this.documentPost);
  
        if (this.passporPostDetail.expired && this.passporPostDetail.number) {
          formData.append(
            "additionals[0][type_id]",
            this.documentList[3].additionals[0].id
          );
          formData.append("additionals[0][value]", this.passporPostDetail.number);
  
          formData.append(
            "additionals[1][type_id]",
            this.documentList[3].additionals[1].id
          );
          formData.append(
            "additionals[1][value]",
            this.passporPostDetail.expired
          );
        }
  
        orderModel.uploadPilgrimDocument(formData)
          .then(() => {
            this.$store.dispatch(
              "notification/success",
              "Dokumen berhasil di unggah."
            );
            this.$bvModal.hide("modal-unggah-dokumen");
            this.getDocumentPilgrim();
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            this.$store.dispatch("notification/error", err);
          });
      },
      setImgPreview: function(e) {
        const [file] = e.target.files;
        if (file) {
          const img = document.getElementById("img-preview");
          const showBeforePreview = document.getElementById(
            "show-before-preview"
          );
  
          showBeforePreview.style.display = "none";
          img.style.display = "block";
          img.src = URL.createObjectURL(file);
          this.documentPost = file;
        }
      },
      doDrop: function(e) {
        const showOver = document.getElementById("show-over");
  
        showOver.style.display = "none";
        const [file] = e.dataTransfer.files;
        if (file) {
          console.log(file);
          const img = document.getElementById("img-preview");
          const showBeforePreview = document.getElementById(
            "show-before-preview"
          );
  
          showBeforePreview.style.display = "none";
          img.style.display = "block";
          img.src = URL.createObjectURL(file);
          this.documentPost = file;
        }
      },
      doDragOver: function(e) {
        e.preventDefault();
        e.stopPropagation();
        const showOver = document.getElementById("show-over");
  
        showOver.style.display = "flex";
      },
      doDragLeave: function(e) {
        e.preventDefault();
        e.stopPropagation();
      },
      getDocumentPilgrim: function() {
        const csDocInput = [
          "scan-ktp",
          "phas-photo",
          "scan-ktp",
          "scan-bukti-chat-wa",
        ];
        this.documentList = [];
        orderModel.listPilgrimDocument(this.$route.params.id).then((resp) => {
          if (
            this.$store.getters["account/accountData"].role == "customer service"
          )
            this.documentList = resp.filter((data) =>
              csDocInput.includes(data.name)
            );
          else this.documentList = resp;
        });
      },
    },
    mounted: function() {
      this.getDocumentPilgrim();
      console.log('masuk mounted document pilgrim')
    },
  };
  </script>