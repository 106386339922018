<template>
    <div :class="[
    'ShowPhoto',
    isShow ? 'is-active-show-photo' : '']" @click="hidden">
      <img :src="img" alt="">
    </div>
  </template>
  
  <script>
  export default {
    props: {
      img: String
    },
    data: () => ({
      isShow: false
    }),
    methods: {
      show: function () {
        this.isShow = true
      },
      hidden: function () {
        this.isShow = false
      }
    }
  }
</script>